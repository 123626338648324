/* PetDisplay.css */
.pet-container {
    width: 200px;
    border: 3px solid #451b04;
    border-radius: 100px;
    padding: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status {
    font-family: Arial, sans-serif;
}
  
.status-text {
    font-size: 16px;
    color: #451b04;
    margin: 5px 0;
}
  
.status-value {
    font-weight: bold;
    color: #451b04;
}  