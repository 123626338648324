.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gif-container {
  position: relative;
  margin-bottom: 20px;
}

.gif-container img {
  position: absolute;
  top: 140px;
  right: 450px;
}

h1 {
  font-size: 3rem; /* Adjust the font size to make it bigger */
  font-family: 'Arial', sans-serif; /* Specify a font family for the heading */
  color: #451b04; /* Set the text color */
  margin-bottom: 20px; /* Add some space below the heading */
  margin-left: 150px;
  text-shadow: 2px 2px 4px rgba(16, 6, 1, 0.1); /* Add a subtle text shadow */
}