/* AnimationPage.css */
.animation-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0D1D5;
  z-index: 10;
}
  
  .animation-page h2 {
    font-size: 24px;
    color: #451b04;
  }